import React from 'react'
import PropTypes from 'prop-types'
import styles from './FeedBack.tw-styles'
import Icon from '../Icon/Icon'
import { feebBackSurveyUrl } from '../../constants/texts'

const FeedBack = ({ closeHandler, url, iconName }) => (
  <div
    className={iconName === 'closeWhite' ? styles.feedBack.sectionIframeReceiptWrapper
      : styles.feedBack.sectionIframeWrapper}
  >
    <button
      className={styles.feedBack.iframeClose}
      type='button'
      aria-label='Feedback'
      onClick={closeHandler}
    >
      <Icon name={iconName} />
    </button>
    <iframe
      className={styles.feedBack.iframeWrapper}
      id='survey'
      title='survey'
      src={url}
    />
    <style jsx global>
      {`
          [data-cognigy-webchat-root] button[data-cognigy-webchat-toggle],
          [data-cognigy-webchat-root] {
            display: none !important;
          }
        `}
    </style>
  </div>
)

FeedBack.defaultProps = {
  closeHandler: () => {},
  url: feebBackSurveyUrl,
  iconName: 'closeGray',
}

FeedBack.propTypes = {
  closeHandler: PropTypes.func,
  url: PropTypes.string,
  iconName: PropTypes.string,
}

export default FeedBack
