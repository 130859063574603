import {
  home,
  signUp,
  waysToEarnPoints,
  rewardsDashboard,
  coupons,
  couponsDigital,
  causeDetail,
  footer,
  about,
  articleDetail,
  rewardsDetail,
  articleListing,
  causeLanding,
  survey,
  rebates,
  campaign,
  alertBox,
  authError,
} from '../../constants/page-source'

module.exports = {
  default: {
    default: {},
    wrapper: 'link-button-default ',
    icon: 'w-18',
  },
  [home]: {
    default: {},
    'Blue-Transparent': {
      wrapper: 'link-button-home-feature-card btn btn-default text-primary',
      icon: 'link-button-home-feature-card-ICON',
    },
    Sustainability: {
      wrapper: 'text-darkGreen',
      icon: '',
    },
    Blue: {
      wrapper:
        'link-button-home-feature-card text-center btn btn-primary cursor-pointer',
      icon: 'link-button-home-feature-card-ICON intro-icon inline',
    },
    DarkGreen: {
      wrapper:
        'link-button-home-feature-card text-center btn btn-darkGreen cursor-pointer',
      icon: 'link-button-home-feature-card-ICON intro-icon inline',
    },
    'Blue-Simple': {
      wrapper: 'link-button-home-feature-card btn btn-primary cursor-pointer',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Green-Transparent': {},
    Green: {},
    'Green-Simple': {},
    'Red-Transparent': {
      wrapper: 'link-button-home-feature-card btn btn-default text-warning',
      icon: 'link-button-home-feature-card-ICON',
    },
    Red: {},
    'Red-Simple': {},
    'Teal-Transparent': {},
    Teal: {},
    'Teal-Simple': {},
    'Black-Simple': {
      wrapper: 'link-button-home-article-linkButton underline outline-non',
      icon: 'link-button-home-article-linkButton-ICON',
    },
    howItSatrtBtn: {
      wrapper:
        'howItSatrtBtn flex flex-wrap mx-auto w-full text-center justify-center focus:outline-none items-center',
      textTitle: 'flex items-center',
      icon: 'iconImage ml-8px w-14 lg:ml-15px',
    },
    closeBtnAfterLoginBtn: {
      wrapper:
        'closeBtn flex flex-wrap mx-auto w-full text-center justify-center focus:outline-none items-center',
      textTitle: 'flex items-center',
      icon: 'iconImage ml-8px w-14 lg:ml-15px',
    },
    'HeroCta-Explore': {
      wrapper: 'link-button-home-feature-card mb-30 md:mb-0 btn btn-primary ',
      icon: 'link-button-home-feature-card-ICON',
    },
    'IOH-Blue': {
      wrapper:
        'link-button-home-feature-card mb-30 md:mb-0 font-bold leading-widest text-18 xl:leading-wider bg-IOHBlue hover:bg-blue md:bg-primary md:hover:bg-lightblue py-11 px-35 md:px-25 text-white text-center block float-left',
      icon: 'link-button-home-feature-card-ICON IOH-BlueSvg',
    },
    'top-bar-contents': {
      wrapper: 'flex flex-wrap ',
      text:
        'alert-text-wrapper tracking-widest flex flex-wrap text-14 leading-leading20',
      iconWrapper: 'icon-wrapper lg:pr-22px hidden lg:block',
      icon: '',
    },
  },
  [rewardsDetail]: {
    default: {
      wrapper:
        'link-button-home-feature-card inline-flex justify-center items-center btn btn-warning-white h-46 w-160',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Blue-Transparent': {},
    Blue: {},
    'Blue-Simple': {},
    'Green-Transparent': {},
    Green: {},
    'Green-Simple': {},
    Red: {
      wrapper: 'link-button-home-feature-card btn btn-warning cursor-pointer',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Red-Transparent': {
      wrapper:
        'link-button-home-feature-card btn btn-warning-white text-warning',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Red-Simple': {
      wrapper:
        ' card-right-cta-home-feature flex outline-none justify-center items-center pt-10 md:pt-0 leading-ultralight text-warning underline text-14 lg:text-18 font-montserratSemiBold font-semibold',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Red-Border': {
      wrapper: 'btn-border btn-warning-border text-warning cursor-pointer',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Teal-Transparent': {},
    Teal: {},
    'Teal-Simple': {},
    'Black-Simple': {},
  },
  [rebates]: {
    default: {
      wrapper: 'link-button-home-feature-card btn btn-default text-warning',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Blue-Transparent': {},
    Blue: {
      wrapper: 'link-button-home-feature-card btn btn-primary cursor-pointer',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Blue-Simple': {},
    'Green-Transparent': {},
    Green: {},
    'Green-Simple': {},
    Red: {
      wrapper: 'link-button-home-feature-card btn btn-warning cursor-pointer',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Red-Transparent': {
      wrapper:
        'link-button-home-feature-card btn btn-warning-white text-warning',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Red-Simple': {
      wrapper:
        ' card-right-cta-home-feature flex outline-none justify-center items-center pt-10 md:pt-0 leading-ultralight text-warning underline text-14 lg:text-18 font-montserratSemiBold font-semibold',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Red-Border': {
      wrapper: 'btn-border btn-warning-border text-warning cursor-pointer',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Teal-Transparent': {
      wrapper:
        'link-button-home-feature-card btn btn-secondary-white text-secondary',
      icon: 'link-button-home-feature-card-ICON',
    },
    Teal: {
      wrapper: 'link-button-home-feature-card btn btn-secondary cursor-pointer',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Teal-Border': {
      wrapper: 'btn-border btn-secondary-border text-secondary cursor-pointer',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Teal-Simple': {},
    'Black-Simple': {},
  },
  [articleListing]: {
    default: {},
    'Blue-Transparent': {},
    Blue: {
      wrapper: 'link-button-home-feature-card btn btn-primary cursor-pointer',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Blue-Simple': {},
    'Green-Transparent': {},
    Green: {},
    'Green-Simple': {},
    'Red-Transparent': {},
    Red: {},
    'Red-Simple': {},
    'Teal-Transparent': {},
    Teal: {},
    'Teal-Simple': {},
    'Black-Simple': {},
  },
  [articleDetail]: {
    default: {},
    'Blue-Transparent': {
      wrapper: 'link-button-home-feature-card btn btn-default text-primary',
      icon: 'link-button-home-feature-card-ICON',
    },
    Blue: {
      wrapper: 'link-button-home-feature-card btn btn-primary',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Blue-Simple': {},
    'Green-Transparent': {
      wrapper: 'link-button-home-feature-card btn btn-default text-success',
      icon: 'link-button-home-feature-card-ICON',
    },
    Green: {},
    'Green-Simple': {},
    'Red-Transparent': {
      wrapper: 'link-button-home-feature-card btn btn-default text-warning',
      icon: 'link-button-home-feature-card-ICON',
    },
    Red: {},
    'Red-Simple': {},
    'Teal-Transparent': {
      wrapper:
        'link-button-home-feature-card btn btn-secondary-white text-secondary',
      icon: 'link-button-home-feature-card-ICON',
    },
    'DarkPink-Transparent': {
      wrapper:
        'link-button-home-feature-card btn btn-info-white text-info border-3 border-white',
      icon: 'link-button-home-feature-card-ICON',
    },
    Teal: {},
    'Teal-Simple': {},
    'Black-Simple': {},
  },
  [about]: {
    default: {},
    'Blue-Transparent': {
      wrapper:
        'link-button-home-article-linkButton  btn bg-white join-us-link-button',
      icon: 'link-button-home-feature-card-ICON',
    },
    Blue: {},
    'Blue-Simple': {},
    'Green-Transparent': {},
    Green: {},
    'Green-Simple': {},
    'Red-Transparent': {},
    Red: {},
    'Red-Simple': {},
    'Teal-Transparent': {},
    Teal: {},
    'Teal-Simple': {},
    'Black-Simple': {},
  },
  [authError]: {
    default: {},
    'Blue-Transparent': {
      wrapper:
        'link-button-home-article-linkButton  btn bg-white join-us-link-button',
      icon: 'link-button-home-feature-card-ICON',
    },
  },
  [signUp]: {
    default: {},
    'Blue-Transparent': {
      wrapper: 'link-button-home-feature-card btn btn-default text-primary',
      icon: 'link-button-home-feature-card-ICON',
    },
    Blue: {
      wrapper: 'link-button-home-feature-card btn btn-primary cursor-pointer',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Blue-Simple': {
      wrapper:
        'signupbutton underline outline-none  text-sm leading-lightmedium lg:text-base font-montserratSemiBold font-semibold text-primaryCta pt-15 lg:pt-20 cursor-pointer',
      icon: 'link-button-home-feature-card-ICON',
    },
    BlueDark: {
      wrapper: 'link-button-home-feature-card btn btn-info cursor-pointer',
      icon: 'link-button-home-feature-card-ICON',
    },
    DarkBlue: {
      wrapper:
        'link-button-home-feature-card btn btn-primaryReceipt cursor-pointer',
      icon: 'link-button-home-feature-card-ICON',
    },
    'DarkBlue-Transparent': {
      wrapper:
        'link-button-home-feature-card btn btn-primaryReceipt-white text-primary',
      icon: 'link-button-home-feature-card-ICON',
    },
    BrightBlue: {
      wrapper:
        'link-button-home-feature-card btn btn-brightBlue cursor-pointer',
      icon: 'link-button-home-feature-card-ICON',
    },
    'BrightBlue-Transparent': {
      wrapper:
        'link-button-home-feature-card btn btn-brightBlue-white text-primary',
      icon: 'link-button-home-feature-card-ICON',
    },
    DarkPink: {
      wrapper: 'link-button-home-feature-card btn btn-info cursor-pointer',
      icon: 'link-button-home-feature-card-ICON',
    },
    'DarkPink-Transparent': {
      wrapper: 'link-button-home-feature-card btn btn-info-white text-info',
      icon: 'link-button-home-feature-card-ICON',
    },
    Green: {
      wrapper: 'link-button-home-feature-card btn btn-success text-white',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Green-Transparent': {
      wrapper:
        'link-button-home-feature-card btn btn-success-white text-success',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Green-Simple': {
      wrapper:
        ' card-right-cta-home-feature flex outline-none justify-center items-center pt-10 md:pt-0 leading-ultralight text-success underline text-14 lg:text-18 font-montserratSemiBold font-semibold',
      icon: 'link-button-home-feature-card-ICON',
    },
    Red: {
      wrapper: 'link-button-home-feature-card btn btn-warning text-white',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Red-Transparent': {
      wrapper:
        'link-button-home-feature-card btn btn-warning-white text-warning',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Red-Simple': {
      wrapper:
        ' card-right-cta-home-feature flex outline-none justify-center items-center pt-10 md:pt-0 leading-ultralight text-warning underline text-14 lg:text-18 font-montserratSemiBold font-semibold',
      icon: 'link-button-home-feature-card-ICON',
    },
    Teal: {
      wrapper: 'link-button-home-feature-card btn btn-secondary cursor-pointer',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Teal-Transparent': {
      wrapper:
        'link-button-home-feature-card btn btn-secondary-white text-secondary',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Teal-Simple': {
      wrapper:
        ' card-right-cta-home-feature flex outline-none justify-center items-center pt-10 md:pt-0 leading-ultralight text-secondary underline text-14 lg:text-18 font-montserratSemiBold font-semibold',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Black-Simple': {},
  },
  [rewardsDashboard]: {
    default: {},
    'Blue-Transparent': {
      wrapper: 'link-button-home-feature-card btn btn-default text-primary',
      icon: 'link-button-home-feature-card-ICON',
    },
    Blue: {
      wrapper: 'link-button-home-feature-card btn btn-primary cursor-pointer',
      icon: 'link-button-home-feature-card-ICON',
    },
    couponsBlue: {
      wrapper:
        'link-button-home-feature-card btn-coupons btn-primary cursor-pointer',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Blue-Simple': {
      wrapper:
        'signupbutton underline outline-none  text-sm leading-lightmedium lg:text-base font-montserratSemiBold font-semibold text-primaryCta pt-15 lg:pt-20 cursor-pointer',
      icon: 'link-button-home-feature-card-ICON',
    },
    BlueDark: {
      wrapper: 'link-button-home-feature-card btn btn-primary cursor-pointer',
      icon: 'link-button-home-feature-card-ICON',
    },
    DarkBlue: {
      wrapper:
        'link-button-home-feature-card btn btn-primaryReceipt cursor-pointer',
      icon: 'link-button-home-feature-card-ICON',
    },
    'DarkBlue-Transparent': {
      wrapper:
        'link-button-home-feature-card btn btn-primaryReceipt-white text-primary',
      icon: 'link-button-home-feature-card-ICON',
    },
    BrightBlue: {
      wrapper:
        'link-button-home-feature-card btn btn-brightBlue cursor-pointer',
      icon: 'link-button-home-feature-card-ICON',
    },
    'BrightBlue-Transparent': {
      wrapper:
        'link-button-home-feature-card btn btn-brightBlue-white text-primary',
      icon: 'link-button-home-feature-card-ICON',
    },
    DarkPink: {
      wrapper: 'link-button-home-feature-card btn btn-info cursor-pointer',
      icon: 'link-button-home-feature-card-ICON',
    },
    'DarkPink-Transparent': {
      wrapper: 'link-button-home-feature-card btn btn-info-white text-info',
      icon: 'link-button-home-feature-card-ICON',
    },
    Green: {
      wrapper: 'link-button-home-feature-card btn btn-success text-white',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Green-Transparent': {
      wrapper:
        'link-button-home-feature-card btn btn-success-white text-success',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Green-Simple': {
      wrapper:
        ' card-right-cta-home-feature flex outline-none justify-center items-center pt-10 md:pt-0 leading-ultralight text-success underline text-14 lg:text-18 font-montserratSemiBold font-semibold',
      icon: 'link-button-home-feature-card-ICON',
    },
    Red: {
      wrapper: 'link-button-home-feature-card btn btn-warning text-white',
      icon: 'link-button-home-feature-card-ICON',
    },
    RedSweeps: {
      wrapper:
        'link-button-home-feature-card btn-sweeps btn-warning text-white',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Red-Transparent': {
      wrapper:
        'link-button-home-feature-card btn btn-warning-white text-warning',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Red-Simple': {
      wrapper:
        ' card-right-cta-home-feature flex outline-none justify-center items-center pt-10 md:pt-0 leading-ultralight text-warning underline text-14 lg:text-18 font-montserratSemiBold font-semibold',
      icon: 'link-button-home-feature-card-ICON',
    },
    Teal: {
      wrapper: 'link-button-home-feature-card btn btn-secondary cursor-pointer',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Teal-Transparent': {
      wrapper:
        'link-button-home-feature-card btn btn-secondary-white text-secondary',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Teal-Simple': {
      wrapper:
        ' card-right-cta-home-feature flex outline-none justify-center items-center pt-10 md:pt-0 leading-ultralight text-secondary underline text-14 lg:text-18 font-montserratSemiBold font-semibold',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Black-Simple': {},
  },
  [coupons]: {
    default: {},
    'Blue-Transparent': {
      wrapper: 'link-button-home-feature-card btn btn-default text-primary',
      icon: 'link-button-home-feature-card-ICON',
    },
    Blue: {
      wrapper: 'link-button-home-feature-card btn btn-primary cursor-pointer',
      icon: 'link-button-home-feature-card-ICON',
    },
    couponsBlue: {
      wrapper:
        'link-button-home-feature-card btn-coupons bg-couponsBlue text-white hover:bg-transpatent border-2 border-couponsBlue hover:bg-white hover:text-couponsBlue cursor-pointer',
      icon: 'link-button-home-feature-card-ICON',
    },
    couponsBlueTransparent: {
      wrapper:
        'link-button-home-feature-card btn-coupons bg-white text-couponsBlue hover:bg-transparent border-2 hover:border-2 hover:border-white hover:text-white cursor-pointer',
      icon: 'link-button-home-feature-card-ICON',
    },
    couponsBlueBorder: {
      wrapper:
        'link-button-home-feature-card btn-coupons bg-transparent border-2 border-white text-white hover:border-2 hover:bg-white hover:text-couponsBlue cursor-pointer',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Blue-Simple': {},
    'Green-Transparent': {
      wrapper: 'link-button-home-feature-card btn btn-default text-success',
      icon: 'link-button-home-feature-card-ICON',
    },
    Green: {},
    'Green-Simple': {},
    'Red-Transparent': {
      wrapper: 'link-button-home-feature-card btn btn-default text-warning',
      icon: 'link-button-home-feature-card-ICON',
    },
    Red: {},
    'Red-Simple': {},
    'Teal-Transparent': {},
    Teal: {
      wrapper: 'link-button-home-feature-card btn btn-secondary',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Teal-Border': {
      wrapper: 'btn-border btn-secondary-border text-secondary cursor-pointer',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Teal-Simple': {
      wrapper:
        'link-button-home-feature-card text-secondary underline  text-14 x:text-18',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Black-Simple': {},
  },
  [couponsDigital]: {
    default: {},
    'Blue-Transparent': {
      wrapper: 'link-button-home-feature-card btn btn-default text-primary',
      icon: 'link-button-home-feature-card-ICON',
    },
    Blue: {
      wrapper: 'link-button-home-feature-card btn btn-primary cursor-pointer',
      icon: 'link-button-home-feature-card-ICON',
    },
    couponsBlue: {
      wrapper:
        'link-button-home-feature-card btn-coupons bg-couponsBlue text-white hover:bg-brightBlue hover:border-2 hover:border-couponsBlue hover:text-white cursor-pointer',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Blue-Simple': {},
    'Green-Transparent': {
      wrapper: 'link-button-home-feature-card btn btn-default text-success',
      icon: 'link-button-home-feature-card-ICON',
    },
    Green: {},
    'Green-Simple': {},
    'Red-Transparent': {
      wrapper: 'link-button-home-feature-card btn btn-default text-warning',
      icon: 'link-button-home-feature-card-ICON',
    },
    Red: {},
    'Red-Simple': {},
    'Teal-Transparent': {},
    Teal: {
      wrapper: 'link-button-home-feature-card btn btn-secondary',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Teal-Simple': {
      wrapper:
        'link-button-home-feature-card w-full font-semibold pt-03 text-secondary underline text-14 x:text-18',
      icon: 'link-button-home-feature-card-ICON',
    },
    'couponsBlue-Simple': {
      wrapper:
        'link-button-home-feature-card w-full font-semibold pt-03 text-couponsBlue underline text-14 x:text-18',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Black-Simple': {},
  },
  [causeDetail]: {
    default: {},
    'Blue-Transparent': {},
    Blue: {
      wrapper: 'link-button-home-feature-card btn btn-primary cursor-pointer',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Blue-Simple': {},
    'Green-Transparent': {},
    Green: {
      wrapper: 'link-button-home-article-linkButton  btn btn-success',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Green-Simple': {
      wrapper:
        ' cause-detail-CTA flex justify-center pt-10 leading-ultralight text-success md:pt-15 lg:pt-05 xl:pt-15 underline text-14 lg:text-18 font-montserratSemiBold font-semibold down-arrow-green',
      textTitle: 'flex',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Red-Transparent': {},
    Red: {},
    'Red-Simple': {},
    'Teal-Transparent': {},
    Teal: {},
    'Teal-Simple': {},
    'Black-Simple': {},
  },
  [causeLanding]: {
    default: {},
    'Blue-Transparent': {},
    Blue: {
      wrapper: 'link-button-home-feature-card btn btn-primary cursor-pointer',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Blue-Simple': {},
    'Green-Transparent': {},
    Green: {
      wrapper: 'link-button-home-article-linkButton  btn btn-success',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Green-Simple': {},
    'Red-Transparent': {},
    Red: {},
    'Red-Simple': {},
    'Teal-Transparent': {},
    Teal: {},
    'Teal-Simple': {},
    'Black-Simple': {},
  },
  [waysToEarnPoints]: {
    default: {},
    'Blue-Transparent': {},
    Blue: {},
    'Blue-Simple': {},
    'Green-Transparent': {},
    Green: {},
    'Green-Simple': {},
    'Red-Transparent': {},
    Red: {},
    'Red-Simple': {
      wrapper:
        ' card-right-cta-home-feature flex outline-none justify-center items-center pt-10 md:pt-0 leading-ultralight text-warning underline text-14 lg:text-18 font-montserratSemiBold font-semibold',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Teal-Transparent': {},
    Teal: {},
    'Teal-Simple': {},
    'Black-Simple': {},
  },
  [footer]: {
    default: {},
    'Blue-Transparent': {},
    Blue: {},
    'Blue-Simple': {
      wrapper: 'footerBottom underline outline-none pt-05 cursor-pointer',
      icon: 'link-button-home-feature-card-ICON',
    },
    'Green-Transparent': {},
    Green: {},
    'Green-Simple': {},
    'Red-Transparent': {},
    Red: {},
    'Red-Simple': {},
    'Teal-Transparent': {},
    Teal: {},
    'Teal-Simple': {},
    'Black-Simple': {},
  },
  adchoice: {
    wrapper: 'adchoice-button',
  },
  'footer-feedbackLink': {
    wrapper: 'footerBottom underline outline-none pt-05 cursor-pointer',
    icon: 'link-button-home-feature-card-ICON',
  },
  [survey]: {
    default: {},
    'Blue-Transparent': {
      wrapper:
        'link-button-home-feature-card btn btn-default text-receiptFeedback',
      icon: 'link-button-home-feature-card-ICON',
    },
  },
  [campaign]: {
    default: {},
    Blue: {
      wrapper:
        'link-button-home-feature-card  text-primary relative flex pr py-20 xl:py-25 text-14 font-semibold underline',
      icon: 'link-button-home-feature-card-ICON',
    },
    IOHGreen: {
      wrapper:
        'text-darkGreen relative flex pr py-20 xl:py-25 text-14 font-semibold underline',
      icon:
        'link-button-home-feature-card-ICON flex items-center ml-5px absolute top-0 -right-15 h-full',
    },
    IOHBlue: {
      wrapper:
        'text-IOHBlue relative flex pr py-20 xl:py-25 text-14 font-semibold underline',
      icon:
        'link-button-home-feature-card-ICON flex items-center ml-5px absolute top-0 -right-15 h-full',
    },
    Green: {
      wrapper:
        'text-success relative flex pr py-20 xl:py-25 text-14 font-semibold underline',
      icon:
        'link-button-home-feature-card-ICON flex items-center ml-5px absolute top-0 -right-15 h-full',
    },
    Red: {
      wrapper:
        'text-warning relative flex pr py-20 xl:py-25 text-14 font-semibold underline',
      icon:
        'link-button-home-feature-card-ICON flex items-center ml-5px absolute top-0 -right-15 h-full',
    },
    Teal: {
      wrapper:
        'text-secondary relative flex pr py-20 xl:py-25 text-14 font-semibold underline',
      icon:
        'link-button-home-feature-card-ICON flex items-center ml-5px absolute top-0 -right-15 h-full',
    },
  },
  [alertBox]: {
    default: {
      button: 'flex flex-wrap',
      text:
        'alert-text flex w-full flex-wrap font-montserratBold font-bold leading-leading20 xl:leading-lightmedium text-14 xl:text-14',
      textTitle: 'textTitle flex items-center',
      iconWrapper: 'icon-wrapper lg:pr-22px hidden lg:block',
      icon: '',
    },
    'top-bar-contents': {
      wrapper: 'flex flex-wrap ',
      text:
        'alert-text-wrapper tracking-widest flex flex-wrap text-14 leading-leading20',
      textTitle: 'icon-wrapper lg:pr-22px hidden lg:block',
      icon: '',
    },
    'alert-box-complete': {
      wrapper: 'flex flex-wrap',
      text:
        'alert-text-wrapper text-left flex flex-wrap font-montserratBold font-bold leading-leading20 xl:leading-lightmedium text-14 xl:text-14',
      textTitle: 'textTitle flex items-center',
      iconWrapper: 'icon-wrapper lg:pr-22px hidden lg:block',
      icon: '',
    },
    alertbarNormalText: {
      button: 'flex flex-wrap',
      text:
        'alert-text flex flex-wrap w-full font-montserratBold font-bold leading-leading20 xl:leading-lightmedium text-14 xl:text-14',
      iconWrapper: 'icon-wrapper lg:pr-22px hidden lg:block',
      icon: '',
    },
    alertbarUserNameText: {
      button: 'flex flex-wrap',
      text:
        'alert-text-wrapper tracking-widest flex flex-wrap text-14 leading-leading20',
      iconWrapper: 'icon-wrapper lg:pr-22px hidden lg:block',
      icon: '',
    },
  },
}
