import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import LazyLoad from 'react-lazyload'
import Button from '../Button'
import Typography from '../Typography/Typography'
import twStyles from './LinkButton.tw-styles'
import linkButtonStyles from './LinkButton.styles'
import Icon from '../Icon/Icon'
import ReceiptScanStore from '../../containers/ReceiptScan/ReceiptScan.store'
import getSurveyId from '../../containers/SurveyLanding/Survey.helper'
import { feedbackSurvey, getMasterSurveyID } from '../../helpers/segmanta.helper'

import LoginStore from '../UserManagement/Login-store'
import FeedBack from '../FeedBack/FeedBack'
import { YOUR_PRIVACY_CHOICE } from '../../constants/texts'

const LinkButton = (props) => {
  const {
    link,
    linkText,
    target,
    className,
    behaviour,
    handler,
    disabled,
    type,
    role,
    gaEventClass,
    pageSource,
    variant,
    surveyName,
    monthlyPromoRewardId,
    featuredSurvey,
    handleMenu,
    surveyContextualLoginText,
    extraTitle,
    mosStartAndEndDate,
    markdownText,
    currentPath,
    autoFocus,
    privacyLink,
  } = props
  let surveyID = ''
  let clickHandler = handler
  let btnlink = (link === '/signup' || link === '/signup/') ? null : link
  if (btnlink === '/sitemap') {
    clickHandler = () => {
      LoginStore.sitemapBackUrl = currentPath
    }
  }
  const pageStyle = twStyles[pageSource] || twStyles.default
  const style = pageStyle[variant] || pageStyle.default
  const [Showfeedback, setShowFeedback] = useState(false)

  const getID = async () => {
    surveyID = await getMasterSurveyID(featuredSurvey, monthlyPromoRewardId, mosStartAndEndDate)
  }

  if (surveyName?.surveyName) {
    if (
      surveyName.surveyName === 'DEQ_MASTER'
      && LoginStore.rewardStatus
    ) getID()
    else if (pageSource === 'alertBox') {
      const handleLoadSurvey = async () => {
        if (getSurveyId(surveyName.surveyName) === process.env.DEQ_SURVEY_TELL_ME_ABOUT_YOURSELF) {
          const surveyEligible = !LoginStore.completeProfile20SurveyTaken
          if (surveyEligible) {
            surveyID = getSurveyId(surveyName.surveyName)
          } else getID()
        } else { surveyID = getSurveyId(surveyName.surveyName) }
      }
      handleLoadSurvey()
    } else surveyID = getSurveyId(surveyName.surveyName)
  }
  const getIcon = (iconName) => (
    <Icon
      name={iconName}
      className={behaviour === 'COPIED' ? 'inheritImp h-16 mb-3px' : style.icon}
    />
  )
  let dataAction = null
  let icon = null
  switch (gaEventClass) {
    case 'event_outbound_link':
      if (linkText === 'AdChoices') {
        dataAction = 'AdChoices'
        icon = (
          <LazyLoad>
            <img
              src='https://images.ctfassets.net/5tx4oa3foqys/7itbMsivHYpTuX1tPE6Ijc/d01e12d4bc0dd4c957cf5cfc76bfe3b0/adchoice.png'
              alt='adchoice'
              className='adchoice-img'
              width={10}
              height={11}
            />
          </LazyLoad>
        )
        break
      }
      dataAction = link
      break
    case 'event_menu_click':
      dataAction = linkText
      break
    default:
      break
  }

  const iconsArr = [{ case: 'With-Arrow-Coral', color: 'arrowUpWhite' },
    { case: 'With-Arrow-Red', color: 'arrowHomeRed' },
    { case: 'With-Arrow-LightGreen', color: 'arrowHomeLightGreen' },
    { case: 'With-Arrow-DarkGreen', color: 'arrowHomeDarkGreen' },
    { case: 'With-Arrow-White', color: 'arrowRightWhite' },
    { case: 'With-Arrow-Blue', color: 'arrowRightBlue' },
    { case: 'With-Arrow-Green', color: 'arrowDownGreen' },
    { case: 'COPIED', color: 'checkCopied' },
    { case: 'With-Arrow-DownWhite', color: 'arrowDownWhite' },
    { case: 'With-Arrow-UpWhite', color: 'arrowUpWhite' },
    { case: 'arrowHomeLightGreen', color: 'arrowHomeLightGreen' },
    { case: 'arrowHomeDarkGreen', color: 'arrowHomeDarkGreen' },
    { case: YOUR_PRIVACY_CHOICE, color: 'privacyChoice' },
  ]

  if (behaviour) {
    const icons = iconsArr.find((item) => (item.case === behaviour))
    icon = getIcon(icons?.color)
  }

  switch (behaviour) {
    case 'Take_Survey':
      clickHandler = () => {
        handleMenu(false)
        feedbackSurvey(surveyID, surveyContextualLoginText)
      }
      break
    case 'Take_Ann_Feedback_Survey':
      clickHandler = () => {
        setShowFeedback(true)
      }
      break
    case 'Privacy_Link':
      btnlink = privacyLink
      break
    default:
      break
  }

  const ariaExpanded = linkText === 'Earn Points' ? { 'aria-expanded': false } : {}

  return (
    <>
      {Showfeedback && (
        <FeedBack
          url={link}
          closeHandler={() => setShowFeedback(false)}
        />
      )}
      <Button
        type={type}
        role={role}
        to={behaviour !== 'Take_Ann_Feedback_Survey' && behaviour !== 'Sample' && btnlink}
        disabled={disabled}
        handler={
          behaviour === 'Scan-Receipt'
            ? () => {
              ReceiptScanStore.setWidgetVisibility(true)
            }
            : clickHandler
        }
        data-action-detail={dataAction}
        {...ariaExpanded}
        className={`${gaEventClass || ''} ${className || ''} ${style.wrapper || ''}`}
        target={target}
        download={target === 'download'}
        aria-label={linkText || 'text'}
        autoFocus={autoFocus}
      >
        <div className={style.text}>
          <p className={style.textTitle}>
            {linkText}
            {extraTitle ? ` (${extraTitle})` : ''}
            {icon}
          </p>
          {markdownText
            && (
            <Typography
              className='markdown-text-icon flex items-center text-left lg:pl-03 alert-text-icon'
              markdown={markdownText}
            />
            )}
        </div>
        <style jsx global>
          {linkButtonStyles}
        </style>
      </Button>
    </>
  )
}

LinkButton.propTypes = {
  link: PropTypes.oneOfType([PropTypes.string]),
  linkText: PropTypes.oneOfType([PropTypes.string]),
  markdownText: PropTypes.oneOfType([PropTypes.string]),
  className: PropTypes.oneOfType([PropTypes.string]),
  target: PropTypes.oneOfType([PropTypes.string]),
  behaviour: PropTypes.string,
  disabled: PropTypes.bool,
  handler: PropTypes.func,
  type: PropTypes.string,
  role: PropTypes.string,
  gaEventClass: PropTypes.string,
  variant: PropTypes.string,
  pageSource: PropTypes.string,
  surveyName: PropTypes.string,
  monthlyPromoRewardId: PropTypes.string,
  featuredSurvey: PropTypes.string,
  handleMenu: PropTypes.func,
  surveyContextualLoginText: PropTypes.string,
  extraTitle: PropTypes.number,
  mosStartAndEndDate: PropTypes.instanceOf(Object),
  currentPath: PropTypes.string,
}

LinkButton.defaultProps = {
  link: null,
  linkText: null,
  markdownText: null,
  className: '',
  variant: '',
  pageSource: '',
  target: '_self',
  behaviour: 'Simple',
  disabled: false,
  handler: () => { },
  handleMenu: () => { },
  type: 'button',
  role: 'button',
  gaEventClass: null,
  surveyName: null,
  monthlyPromoRewardId: null,
  featuredSurvey: null,
  surveyContextualLoginText: null,
  extraTitle: null,
  mosStartAndEndDate: null,
  currentPath: '',
}
export default observer(LinkButton)
